// Colors
$perfect-white: #ffffff;
$frosty-frost: #bfd9ed;
$deep-below: #14233c;
$cold-azure: #1c75bb;
$super-alert: #ec1d24;
$gray-100: #f5f5f7;
$gray-200: #dee2e6;
$gray-300: #6c757d;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$background: $perfect-white;
$background-secondary: $frosty-frost;
$surface: $perfect-white;
$surface-secondary: $frosty-frost;
// $error: $?;
$primary: $cold-azure;
$secondary: $super-alert;

$on-background: $deep-below;
$on-background-secondary: $deep-below;
$on-surface: $deep-below;
$on-surface-secondary: $deep-below;
// $on-error: $?;
$on-primary: $perfect-white;
$on-secondary: $perfect-white;


// Dimensions
$max-section-width: 1060;
$max-width: 770px;
$radius: 1.5rem;
